import ModalController from './abstract/ModalController';
import { Toast } from 'bootstrap';
export default class default_1 extends ModalController {
    static targets = ['cancelUploadButton', 'cancelMessage', 'uploadMessage', 'showUploadForm'];
    connect = () => {
        //  @ts-ignore
        if (this.hasCancelUploadButtonTarget) {
            const workflowId = this.cancelUploadButtonTarget.getAttribute('data-workflow-id');
            if (workflowId !== null) {
                this.pollForKalturaId(workflowId);
            }
        }
    };
    showNotificationOnCancel = () => {
        const notificationElement = document.querySelector('[data-notification="cancel"]');
        if (notificationElement) {
            const toast = new Toast(notificationElement);
            toast.show();
        }
    };
    cancelUpload = async () => {
        const kalturaId = this.cancelUploadButtonTarget.getAttribute('data-kaltura-id');
        const workflowId = this.cancelUploadButtonTarget.getAttribute('data-workflow-id');
        const csrfToken = this.cancelUploadButtonTarget.getAttribute('data-csrf-token');
        if (kalturaId && workflowId && csrfToken) {
            const response = await fetch(`/workflow/video/delete-upload/${kalturaId}/${workflowId}?csrf=${csrfToken}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                this.cancelUploadButtonTarget.style.display = 'none';
                this.cancelMessageTarget.style.display = 'block';
                this.uploadMessageTarget.style.display = 'none';
                this.showUploadFormTarget.classList.remove('d-none');
                this.showNotificationOnCancel();
            }
        }
    };
    cancelUploadFromTable = async () => {
        const kalturaId = this.cancelUploadButtonTarget.getAttribute('data-kaltura-id');
        const workflowId = this.cancelUploadButtonTarget.getAttribute('data-workflow-id');
        const csrfToken = this.cancelUploadButtonTarget.getAttribute('data-csrf-token');
        if (kalturaId && workflowId && csrfToken) {
            const response = await fetch(`/workflow/video/delete-upload/${kalturaId}/${workflowId}?csrf=${csrfToken}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                this.cancelUploadButtonTarget.style.display = 'none';
                this.cancelMessageTarget.style.display = 'block';
                this.uploadMessageTarget.style.display = 'none';
                this.showUploadFormTarget.classList.remove('d-none');
                const buttonElement = document.querySelector('[data-bs-target="#uploadVideoModal"]');
                if (buttonElement) {
                    buttonElement.classList.remove('d-none');
                }
                this.showNotificationOnCancel();
            }
        }
    };
    pollForKalturaId = async (workflowId) => {
        let kalturaId = null;
        let sleep = 500; // Initial delay
        // Polling loop
        while (!kalturaId) {
            const response = await fetch(`/workflow/video/kaltura-id/${workflowId}`, {
                method: 'GET',
            });
            if (response.ok) {
                const data = await response.json();
                kalturaId = data.kalturaId;
                if (kalturaId) {
                    // Store the kalturaId in the button attribute
                    this.cancelUploadButtonTarget.setAttribute('data-kaltura-id', kalturaId);
                    // Enable the cancel button
                    this.cancelUploadButtonTarget.disabled = false;
                }
            }
            if (!kalturaId) {
                // Wait for a while before trying again
                await new Promise(resolve => setTimeout(resolve, sleep));
                sleep = Math.min(sleep + 250, 8000); // Increase sleep time, capping at 8000ms
            }
        }
    };
}
