import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['abortVideoUploadButton'];
    abortVideoUpload = async () => {
        const videoWorkflowId = this.abortVideoUploadButtonTarget.getAttribute('data-workflow-id');
        await fetch(`/workflow/video/abort-video-upload/${videoWorkflowId}`, {
            method: 'POST',
        });
    };
}
