import { hideErrors, showError } from '../helpers/form-helper';
import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static targets = ['commentArea', 'inputDueDate', 'selectUser', 'publishLiveOnLastStepChecked'];
    static outlets = ['counted-field', 'comment'];
    connect = () => {
        this.formElement = document.querySelector('[name="workflow_state_change"]');
        this.assignedUserSelect = document.getElementById('workflow_state_change_assignedUser');
        this.statusSelect = document.getElementById('workflow_state_change_states');
        this.element.addEventListener('hide.bs.modal', this.hideModalCleanup);
        //@ts-ignore
        if (this.hasCommentAreaTarget) {
            this.element.addEventListener('show.bs.modal', () => (this.enterBlockerElements = [this.commentAreaTarget, this.selectUserTarget].filter(Boolean)));
        }
        if (this.element.dataset.validated === undefined || this.element.dataset.validated === 'false') {
            return;
        }
        this.modal.show();
    };
    hideModalCleanup = () => {
        hideErrors(this.element);
        //@ts-ignore
        if (this.hasCountedFieldOutlet) {
            if (this.countedFieldOutlet) {
                this.countedFieldOutlet.clear();
            }
        }
    };
    clickSubmitButton = async () => {
        this.submitModalButtonTarget.disabled = true;
        hideErrors(this.element);
        let hasErrors = false;
        if (!this.statusSelect.tomselect?.getValue()) {
            showError(this.statusSelect, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
            this.submitModalButtonTarget.disabled = false;
        }
        if (!this.assignedUserSelect.tomselect?.getValue()) {
            showError(this.assignedUserSelect, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
            this.submitModalButtonTarget.disabled = false;
        }
        if (this.inputDueDateTarget._flatpickr.selectedDates.length < 1) {
            showError(this.inputDueDateTarget, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
            this.submitModalButtonTarget.disabled = false;
        }
        //check if date is 2 hours in future
        if (this.inputDueDateTarget._flatpickr.selectedDates.length == 1 &&
            new Date(Date.now() + 2 * 60 * 60 * 1000) >= this.inputDueDateTarget._flatpickr.selectedDates[0]) {
            showError(this.inputDueDateTarget, await translate('workflow.validation.date_2h_in_future', 'admin'));
            hasErrors = true;
            this.submitModalButtonTarget.disabled = false;
        }
        if (!hasErrors) {
            await this.handleFormSubmit();
        }
    };
    clickSubmitButtonForPublishLive = async () => {
        this.submitModalButtonTarget.disabled = true;
        hideErrors(this.element);
        let hasErrors = false;
        //@ts-ignore
        if (this.hasPublishLiveOnLastStepCheckedTarget) {
            if (this.publishLiveOnLastStepCheckedTarget) {
                if (!this.publishLiveOnLastStepCheckedTarget.checked) {
                    showError(this.publishLiveOnLastStepCheckedTarget, await translate('workflow.validation.required', 'admin'));
                    hasErrors = true;
                    this.submitModalButtonTarget.disabled = false;
                }
            }
        }
        if (!hasErrors) {
            await this.handleFormSubmit();
        }
    };
    handleFormSubmit = async () => {
        if (this.formElement === null) {
            return;
        }
        //@ts-ignore
        if (this.hasCommentOutlet && this.hasCommentAreaTarget) {
            if (this.commentOutlet && this.commentAreaTarget) {
                const success = await this.commentOutlet.submitComment(this.formElement, this.commentAreaTarget);
                if (!success) {
                    return;
                }
            }
        }
        this.modal.hide();
        location.reload();
    };
}
