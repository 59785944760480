import { hideError, showError } from '../helpers/form-helper';
import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static targets = [
        'submitButton',
        'titleField',
        'videoField',
        'spinnerWrapper',
        'descriptionField',
        'cancelUploadButton',
    ];
    connect = () => {
        this.submitButtonTarget.addEventListener('click', this.submit);
    };
    validate = async () => {
        if (this.titleFieldTarget.value.length <= 0) {
            showError(this.titleFieldTarget, await translate('workflow.validation.required', 'admin'));
            return false;
        }
        if (!this.videoFieldTarget.files) {
            showError(this.videoFieldTarget, await translate('workflow.validation.required', 'admin'));
            return false;
        }
        if (this.videoFieldTarget.files.length == 0) {
            showError(this.videoFieldTarget, await translate('workflow.validation.required', 'admin'));
            return false;
        }
        for (let i = 0; i < this.videoFieldTarget.files.length; i++) {
            const file = this.videoFieldTarget.files[i];
            const mbFileSize = file.size / 1024 / 1024;
            if (mbFileSize > 3718) {
                // 3718 mb == 3900 MB
                showError(this.videoFieldTarget, await translate('workflow.validation.file_to_big', 'admin'));
                return false;
            }
            if (!file.type.match('video.*')) {
                showError(this.titleFieldTarget, await translate('workflow.validation.only_videos', 'admin'));
                return false;
            }
        }
        return true;
    };
    hideErrors = () => {
        hideError(this.titleFieldTarget);
        hideError(this.videoFieldTarget);
    };
    submit = async () => {
        this.hideErrors();
        if (await this.validate()) {
            document.querySelector('#submit-form-button')?.click();
            //show loading spinner
            if (this.spinnerWrapperTarget) {
                this.spinnerWrapperTarget.style.display = 'flex';
            }
        }
    };
}
