import { hideErrors, showError } from '../helpers/form-helper';
import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static targets = ['commentArea', 'inputDueDate', 'toggleObserve'];
    static outlets = ['counted-field', 'comment', 'sidebar'];
    connect = () => {
        this.formElement = document.querySelector('[name="workflow_assigned_user_change"]');
        this.assignedUserSelect = document.getElementById('workflow_assigned_user_change_assignedUser');
        this.element.addEventListener('hide.bs.modal', this.hideModalCleanup);
        this.element.addEventListener('show.bs.modal', () => (this.enterBlockerElements = [this.commentAreaTarget, this.assignedUserSelect]));
    };
    hideModalCleanup = () => {
        hideErrors(this.element);
        this.countedFieldOutlet.clear();
    };
    clickSubmitButton = async () => {
        hideErrors(this.element);
        let hasErrors = false;
        if (!this.assignedUserSelect.tomselect?.getValue()) {
            showError(this.assignedUserSelect, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
        }
        const inputDueDate = document.querySelector('#workflow_assigned_user_change_dueDate');
        if (inputDueDate && (inputDueDate.value === '' || inputDueDate.value.length < 1)) {
            showError(this.inputDueDateTarget, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
        }
        //check if date is 2 hours in future
        if (inputDueDate.value.length > 1 &&
            new Date(Date.now() + 2 * 60 * 60 * 1000) >= new Date(inputDueDate.value)) {
            showError(this.inputDueDateTarget, await translate('workflow.validation.date_2h_in_future', 'admin'));
            hasErrors = true;
        }
        if (!hasErrors) {
            this.handleFormSubmit();
        }
    };
    handleFormSubmit = async () => {
        if (this.formElement === null) {
            return;
        }
        const success = await this.commentOutlet.submitComment(this.formElement, this.commentAreaTarget);
        if (!success) {
            return;
        }
        const assignedUserSelect = this.assignedUserSelect.tomselect;
        if (!assignedUserSelect) {
            return;
        }
        const assignedUserName = this.getDisplayNameFromDisplayNameWithEmail(assignedUserSelect.options[assignedUserSelect.items[0]].text);
        this.successNotification?.show();
        this.sidebarOutlet.setAssignedUser(assignedUserName);
        location.reload();
    };
    getDisplayNameFromDisplayNameWithEmail = (text) => {
        return text.split('(')[0].trimEnd();
    };
    toggleObserveWorkflow = async () => {
        const sbbEmail = this.toggleObserveTarget.dataset.propOne;
        const workflowId = parseInt(this.toggleObserveTarget.dataset.propTwo);
        const token = this.toggleObserveTarget.dataset.token;
        const response = await fetch('/workflow/observe/', {
            method: 'POST',
            body: JSON.stringify({ sbbEmail, workflowId, token }),
        });
        const resObject = await response.json();
        if (resObject.error) {
            return;
        }
        const workflowObservers = document.getElementById('workflowObservers');
        workflowObservers.innerText = resObject.observers.length.toString();
        const dropdown = document.getElementById('dropdown-collapse');
        dropdown.innerHTML = '';
        for (const observer of resObject.observers) {
            const listItem = document.createElement('li');
            listItem.id = observer.email;
            const span = document.createElement('span');
            span.textContent = observer.name;
            listItem.appendChild(span);
            dropdown.appendChild(listItem);
        }
    };
}
